const appConfig = {
  apiOrigin: process.env.REACT_APP_API_ORIGIN || 'https://api.techatinfinity.in',
  amplifyConfig: {
    aws_cognito_region: process.env.REACT_APP_COGNOTO_REGION,
    aws_user_pools_id: process.env.REACT_APP_USERPOOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_CLIENT_ID,
  },
};

export default appConfig;
