/* eslint-disable no-param-reassign */
import { useRef, useEffect } from 'react';
import axios from 'axios';
import appConfig from './appConfig';

import useAuth from './useAuth';

export default () => {
  const { token, user } = useAuth({
    options: appConfig.amplifyConfig,
  });
  const { apiOrigin = 'http://localhost:3001' } = appConfig;
  const api = useRef(
    axios.create({
      baseURL: `${apiOrigin}/`,
    }),
  );
  useEffect(() => {
    const currentAPI = api.current;
    const requestInterceptorId = currentAPI.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.authorization = `Bearer ${token}`;
        }
        // const token = await getAccessTokenSilently();

        // config.cancelToken = axios.CancelToken.source().token;
        return config;
      },
    );
    return () => {
      currentAPI.interceptors.request.eject(requestInterceptorId);
    };
  }, [token]);
  return {
    api: api.current,
    token,
    user,
  };
};
