import React from 'react';
import './App.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifyAuthContainer } from '@aws-amplify/ui-react';
// import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import appConfig from './appConfig';
// import { Context } from './Store';
// import { setAuthState, setAuthUser } from './Actions';
import useAuth from './useAuth';
import TaskDashboard from './Screens/TaskDashboard';

Amplify.configure(appConfig.amplifyConfig);

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const { isSignedIn } = useAuth({
    options: appConfig.amplifyConfig,
  });

  // const [authState, setAuthStateApp] = React.useState();
  // const { authUser } = state;

  const theme = React.useMemo(
    () => createMuiTheme({
      palette: {
        type: prefersDarkMode ? 'dark' : 'light',
      },
    }),
    [prefersDarkMode],
  );

  return isSignedIn ? (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AmplifyAuthContainer>
          <AmplifyAuthenticator>
            <TaskDashboard />
          </AmplifyAuthenticator>
        </AmplifyAuthContainer>
        <CssBaseline />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  ) : (
    <AmplifyAuthContainer><AmplifyAuthenticator /></AmplifyAuthContainer>
  );
}

export default App;
