/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import {
  ListItem,
  // Checkbox,
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import moment from 'moment';

const TaskListItem = memo((props) => (
  <ListItem divider={props.divider}>
    {/* <Checkbox
      onClick={props.onCheckBoxToggle}
      checked={props.checked}
      disableRipple
    /> */}
    <ListItemText primary={props.content} secondary={moment(new Date(props.createdAt)).fromNow()} />
    <ListItemSecondaryAction>
      <IconButton aria-label="Delete Todo" onClick={props.onButtonClick}>
        <DeleteOutlined />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
));
export default TaskListItem;
