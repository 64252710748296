/* eslint-disable no-param-reassign */
import { useContext, useEffect, useState } from 'react';
import types from '../Reducer/types';
import noteService from '../Services/noteService';
import { Context } from '../Store';
import useAPI from '../useAPI';

export const useInputValue = (initialValue = '') => {
  const [inputValue, setInputValue] = useState(initialValue);

  return {
    inputValue,
    changeInput: (event) => setInputValue(event.target.value),
    clearInput: () => setInputValue(''),
    keyInput: (event, callback) => {
      if (event.which === 13 || event.keyCode === 13) {
        callback(inputValue);
        return true;
      }

      return false;
    },
  };
};

export const useTasks = (initialValue = []) => {
  const { state, dispatch } = useContext(Context);
  const { tasks = initialValue } = state;
  const { api, token, user } = useAPI();

  useEffect(() => {
    if (token) {
      noteService.initInstance(api);
      noteService.fetchTasks().then((resp) => {
        dispatch({
          type: types.SET_TASKS,
          payload: resp,
        });
      }).catch(console.log);
    }
  }, [api, token]);

  return {
    tasks,
    user,
    addTask: (text) => {
      if (text !== '') {
        const randomId = Math.random().toString(36).substring(2);
        const addedNotes = tasks.concat({
          content: text,
          createdAt: Date.now(),
          checked: false,
          id: randomId,
        });
        dispatch({
          type: types.SET_TASKS,
          payload: addedNotes,
        });
        noteService.addTask(text).then((resp) => {
          const taskIndex = addedNotes.findIndex((task) => task.id === randomId);
          if (taskIndex !== -1) {
            addedNotes[taskIndex] = resp;
          }
          dispatch({
            type: types.SET_TASKS,
            payload: addedNotes,
          });
        }).catch(console.log);
      }
    },
    completeTask: (idx) => {
      dispatch({
        type: types.SET_TASKS,
        payload: tasks.map((todo, index) => {
          if (idx === index) {
            todo.checked = !todo.checked;
          }

          return todo;
        }),
      });
    },
    removeTask: (idx) => {
      dispatch({
        type: types.SET_TASKS,
        payload: tasks.filter((task) => task.noteId !== idx),
      });
      noteService.removeNote(idx).then(console.log).catch(console.log);
    },
  };
};
