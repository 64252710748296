import defaultState from '../Store/defaultState';
import types from './types';

const Reducer = (state, action) => {
  if (state === null) {
    localStorage.removeItem('localState');
    return defaultState;
  }

  switch (action.type) {
    case types.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case types.SET_AUTH_STATE:
      return {
        ...state,
        authState: action.payload,
      };
    case types.SET_AUTH_USER:
      return {
        ...state,
        authUser: action.payload,
      };
    case types.SET_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;
