/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { List, Paper } from '@material-ui/core';
import TaskListItem from './TaskListItem';

const TaskList = memo((props) => (
  <>
    {props.items.length > 0 && (
      <Paper style={{ margin: 16 }}>
        <List>
          {props.items.map((task, idx) => (
            <TaskListItem
              {...task}
              key={`TaskItem.${task.noteId}${task.id}`}
              divider={idx !== props.items.length - 1}
              onButtonClick={() => props.onItemRemove(task.noteId)}
              onCheckBoxToggle={() => props.onItemCheck(idx)}
            />
          ))}
        </List>
      </Paper>
    )}
  </>
));
export default TaskList;
