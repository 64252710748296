/* eslint-disable no-param-reassign */
let instance = null;

const initInstance = (api) => {
  instance = api;
};

const fetchTasks = async () => {
  const resp = await instance.get('/notes');
  return resp.data;
};

const addTask = async (content) => {
  const resp = await instance.post('/notes', {
    content,
  });
  return resp.data;
};

const removeNote = async (id) => {
  const resp = await instance.delete(`/notes/${id}`);
  return resp.data;
};

const noteService = {
  axiosInstance: instance,
  initInstance,
  fetchTasks,
  addTask,
  removeNote,
};

export default noteService;
