/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import {
  AppBar, Toolbar, Typography, Paper, Button,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';

const Layout = memo((props) => (
  <Paper
    elevation={0}
    style={{ padding: 0, margin: 0 }}
  >
    <AppBar color="primary" position="static" style={{ height: 64 }}>
      <Toolbar style={{ height: 64 }}>
        <Typography
          variant="h6"
          style={{
            flexGrow: 1,
          }}
          color="inherit"
        >
          Notes
        </Typography>
        <Button
          color="inherit"
          onClick={() => {
            Auth.signOut().then(() => {
              console.log('Logged out...');
            }).catch(console.log);
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
    {props.children}
  </Paper>
));
export default Layout;
