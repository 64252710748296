/* eslint-disable no-mixed-operators */
import './styles.css';

import React, { memo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TaskList from './TaskList';
import AddTask from './AddTask';
import Layout from './Layout';
import { useInputValue, useTasks } from './custom-hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const TaskDashboard = memo(() => {
  const {
    inputValue, changeInput, clearInput, keyInput,
  } = useInputValue();
  const classes = useStyles();
  const {
    tasks, addTask, completeTask, removeTask, user,
  } = useTasks();

  const clearInputAndAddTodo = () => {
    clearInput();
    addTask(inputValue);
  };
  const renderUserInfo = () => (
    <List className={classes.root}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <AccountCircleIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={String(user.username).toUpperCase()}
          secondary={`${user.attributes.email}`}
        />
      </ListItem>
    </List>
  );

  return (
    <Layout>
      {user && user.username && renderUserInfo() || null}
      <AddTask
        inputValue={inputValue}
        onInputChange={changeInput}
        onButtonClick={clearInputAndAddTodo}
        onInputKeyPress={(event) => keyInput(event, clearInputAndAddTodo)}
      />
      <TaskList
        items={tasks}
        onItemCheck={(idx) => completeTask(idx)}
        onItemRemove={(idx) => removeTask(idx)}
      />
    </Layout>
  );
});

export default TaskDashboard;
